var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-list-list" },
    [
      _c("app-list", {
        ref: "whiteList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建白名单",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "editBox" },
            [
              _c(
                "el-form",
                {
                  ref: "whiteListForm",
                  attrs: {
                    model: _vm.whiteForm,
                    rules: _vm.rules2,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "whiteListName" } },
                    [
                      _c("label", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*")
                        ]),
                        _vm._v("  名称")
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称", clearable: "" },
                        model: {
                          value: _vm.whiteForm.whiteListName,
                          callback: function($$v) {
                            _vm.$set(_vm.whiteForm, "whiteListName", $$v)
                          },
                          expression: "whiteForm.whiteListName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "网址/IP", prop: "ip" } },
                    [
                      _c("label", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*")
                        ]),
                        _vm._v("  网址/IP")
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入网址/IP",
                          maxlength: "32",
                          clearable: ""
                        },
                        model: {
                          value: _vm.whiteForm.ip,
                          callback: function($$v) {
                            _vm.$set(_vm.whiteForm, "ip", $$v)
                          },
                          expression: "whiteForm.ip"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细描述", prop: "description" } },
                    [
                      _c("el-input", {
                        staticClass: "textarea",
                        attrs: {
                          type: "textarea",
                          maxlength: "300",
                          placeholder: "请输入安装位置",
                          clearable: ""
                        },
                        model: {
                          value: _vm.whiteForm.description,
                          callback: function($$v) {
                            _vm.$set(_vm.whiteForm, "description", $$v)
                          },
                          expression: "whiteForm.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "140px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }