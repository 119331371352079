<template>
  <div class="white-list-list">
    <app-list ref="whiteList" :opt="opt" @get="onGet"></app-list>
    <el-dialog title="新建白名单" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <div class="editBox">
        <el-form ref="whiteListForm" :model="whiteForm" :rules="rules2" label-width="120px">
          <el-form-item label="名称" prop="whiteListName">
            <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;名称</label>
            <el-input placeholder="请输入名称" v-model="whiteForm.whiteListName" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="网址/IP" prop="ip">
          <label slot="label"><span style="color:red">*</span>&nbsp;&nbsp;网址/IP</label>
            <el-input placeholder="请输入网址/IP" maxlength="32" v-model="whiteForm.ip" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="详细描述" prop="description">
            <el-input type="textarea" class="textarea" maxlength="300" placeholder="请输入安装位置"
              v-model="whiteForm.description" clearable>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" style="width:140px" @click="submit()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  let validAdName = (rule, value, callback) => {
    //支持输入中英文字母，汉字,数字
    let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{2,12}$/;
    if (value == "" || value == undefined) {
      callback(new Error("请输入名称"));
    } else if (!reg.test(value)) {
      callback(new Error("名称格式不对"));
    } else {
      callback();
    }

  };
  let validIP = (rule, value, callback) => {
    let reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
    if (value == "" || value == undefined) {
      callback(new Error("请输入网址/IP"));
    } else if (reg.test(value)) {
      callback(new Error("只能包含大小写字母、数字、英文符号,请重新输入"));
    } else {
      callback();
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        editFlag: 'add', //add添加 edit编辑
        currentId: '',
        whiteForm: {
          whiteListName: "",
          ip: "",
          description: ""
        },
        rules2: {
          whiteListName: [{
            validator: validAdName,
            trigger: ['blur']
          }],
          ip: [{
            validator: validIP,
            trigger: ['blur']
          }],
        },
        opt: {
          title: "白名单管理",
          search: [],
          columns: [{
              label: "名称",
              key: "name",
            },
            {
              label: "网址/IP",
              key: "ip"
            },
            {
              label: "详细描述",
              key: "description"
            },
          ],
          buttons: [{
              type: 0,
              on() {
                if (_this.$refs["whiteListForm"]) {
                  _this.$refs["whiteListForm"].resetFields(); //初始化表单
                }
                _this.editFlag = 'add';
                _this.dialogVisible = true
              }
            },
            {
              type: 1,
              on(data) {
                if (_this.$refs["whiteListForm"]) {
                  _this.$refs["whiteListForm"].resetFields(); //初始化表单
                }
                _this.editFlag = 'edit';
                _this.currentId = data.data.id;
                _this.whiteForm.whiteListName = data.data.name;
                _this.whiteForm.ip = data.data.ip;
                _this.whiteForm.description = data.data.description;
                _this.dialogVisible = true
              }
            },
            {
              type: 2,
              on(data) {
                if (data.list.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                _this
                  .$confirm("此操作将删除选择项, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                  .then(() => {
                    console.log(data);
                    console.log(data.list);
                    _this
                      .post("/v1/ipWhiteList/deleteByIds", data.ids)
                      .then(function (res) {
                        _this.$message({
                          message: "删除成功",
                          type: "success"
                        });
                        data.refresh();
                      })
                      .catch(function (res) {
                        _this.$message({
                          message: res.data.msg,
                          type: "warning"
                        });
                      });
                  })
                  .catch(function () {});
              }
            }
          ]
        }
      };
    },
    activated() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("/v1/ipWhiteList/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.stateStr = ["禁用", "正常"][item.state];
          }
          opt.cb(res.data);
        });
      },
      submit() {
        let _this = this;
        this.$refs['whiteListForm'].validate((valid) => {
          if (valid) {
            let url = '';
            let msg = '';
            var dto = {
              name: this.whiteForm.whiteListName,
              ip: this.whiteForm.ip,
              description: this.whiteForm.description,
            };
            if (this.editFlag == 'edit') {
              dto.id = this.currentId;
              url = '/v1/ipWhiteList/update';
              msg = '修改成功';
            } else {
              url = '/v1/ipWhiteList/add';
              msg = '添加成功';
            }
            _this.post(url, dto).then(function () {
              _this.$message({
                showClose: true,
                message: msg,
                type: "success"
              });
              _this.dialogVisible = false;
              _this.$refs.whiteList.onRefresh();
            });
          }
        })
      }


    }
  };
</script>
<style scoped lang="scss">
  .white-list-list {
    .editBox {
      width: 90%;
      display: inline-block;
      margin: auto;

      .editTitle {
        display: inline-block;
        padding-right: 10px;
        text-align: right;
        width: 101px;
        color: #606266
      }

      .textarea {
        // width: 310px;
        height: 80px;
        border-color: #dcdfe6;
        color: #303133;

        textarea {
          width: 100%;
          height: 100%;
          resize: none;
        }
      }
    }

    .dialog-footer {
      width: 100%;
      text-align: center;
    }
  }
</style>